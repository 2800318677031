<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.importOffer') }}</h5>
                <Toast />
                <Dialog :header="$t('sportsbets.dialog.confirmImportTitle')" v-model:visible="showModal" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p>
                        {{ fixturesActive ? $tc('sportsbets.dialog.confirmImportText', { count: selectedIds.length }) : $tc('sportsbets.dialog.confirmImportTextOutright', { count: selectedIds.length }) }}
                    </p>
                    <template #footer>
                        <Button :label="$t('buttons.close')" @click="closeModal()" icon="pi pi-times" class="p-button-danger" />
                        <Button :label="$t('buttons.confirm')" @click="importFixtures()" icon="pi pi-check" class="p-button-success" />
                    </template>
                </Dialog>
                <TabView v-model:activeIndex="activeTab">
                    <TabPanel :header="$t('sportsbets.fixtures')">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="Name" :placeholder="$t('dropdown.placeholder.sport')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="categoriesModel" v-show="showCategoryDdl" :options="categories" optionLabel="Name" :placeholder="$t('dropdown.placeholder.category')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="tournamentsModel" v-show="showTournamentsDdl" :options="tournaments" optionLabel="Name" :placeholder="$t('dropdown.placeholder.tournament')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2 p-text-right"><Button v-show="showFetchBtn" :label="$t('buttons.getFixtures')" class="p-button-raised p-button-success" @click="readItems()" /></div>
                        </div>
                        <div class="p-col-12 p-pb-0 p-md-1 p-md-offset-11 p-text-center">
                            <Button v-show="showFixturesTable" :label="$t('buttons.selectAll')" class="p-button-rounded p-button-text p-mr-2 p-mt-2 p-button-info" @click="selectAll()" />
                        </div>
                        <transition-group name="p-message" tag="div" v-show="showNoEvents" class="p-col-12 p-mb-2">
                            <Message :key="errorKeyCount" :severity="'info'">{{ $t('sportsbets.alerts.noEventsToImport') }}</Message>
                        </transition-group>
                        <div class="p-col-12 p-pb-0 p-md-2 p-md-offset-5 p-text-center" v-show="showImportLoading">
                            <i class="pi pi-spin pi-spinner" style="font-size: 4rem; color: #83ace8"></i>
                        </div>
                        <DataTable v-show="showFixturesTable" :value="items" :paginator="true" :rows="perPage" :loading="loading" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true">
                            <template #header> </template>
                            <Column
                                ><template #body="slotProps">
                                    <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                                </template>
                            </Column>
                            <Column field="id" :header="$t('sportsbets.fixtureId')" :sortable="true"></Column>
                            <Column field="StardDate" :header="$t('sportsbets.date')" :sortable="true">
                                <template #body="{ data }"> {{ formatCSDateAndTime(data.StardDate) }} </template>
                            </Column>

                            <Column field="Name" :header="$t('sportsSettings.name')" :sortable="true"> </Column>
                            <Column>
                                <template #body="{ data }"><Checkbox v-model="selectedIds" :value="data.id" /></template
                            ></Column>
                        </DataTable>
                        <div class="p-col-12 p-pb-0 p-md-4 p-md-offset-8 p-text-right">
                            <Button v-show="showFixturesTable" :label="$t('buttons.importEvents')" class="p-button-raised p-mr-2 p-mt-2 p-button-info" @click="confirmImport()" />
                        </div>
                    </TabPanel>
                    <TabPanel :header="$t('sportsbets.outrights')">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="Name" :placeholder="$t('dropdown.placeholder.sport')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2 p-md-offset-8 p-text-right">
                                <Button v-show="showFetchBtn" :label="fixturesActive ? $t('buttons.getFixtures') : $t('buttons.getOutrights')" class="p-button-raised p-button-success" @click="readItems()" />
                            </div>
                        </div>
                        <div class="p-col-12 p-pb-0 p-md-1 p-md-offset-11 p-text-right">
                            <Button v-show="showOutrightsTable" :label="$t('buttons.selectAll')" class="p-button-rounded p-button-text p-mr-2 p-mt-2 p-button-info" @click="selectAll()" />
                        </div>
                        <div class="p-col-12 p-pb-0 p-md-2 p-md-offset-5 p-text-center" v-show="showImportLoading">
                            <i class="pi pi-spin pi-spinner" style="font-size: 4rem; color: #83ace8"></i>
                        </div>
                        <DataTable v-show="showOutrightsTable" :value="itemsOutrights" :paginator="true" :rows="perPage" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true">
                            <template #header> </template>
                            <Column
                                ><template #body="slotProps">
                                    <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                                </template>
                            </Column>
                            <Column field="Name" :header="$t('sportsSettings.name')"> </Column>
                            <Column class="p-text-right">
                                <template #body="{ data }"><Checkbox v-model="selectedIds" :value="data.id" /></template
                            ></Column>
                        </DataTable>
                        <div class="p-col-12 p-pb-0 p-md-4 p-md-offset-8 p-text-right">
                            <Button v-show="showOutrightsTable" :label="$t('buttons.importEvents')" class="p-button-raised p-mr-2 p-mt-2 p-button-info" @click="confirmImport()" />
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </div>
</template>
<script>
import { BetradarApi } from '../../service/BetradarApi';
import { uiSettings } from '../../settings/uiSettings';
export default {
    name: 'LottoResults',
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            itemsOutrights: [],
            BetradarApi: new BetradarApi(),
            startDateModel: null,
            endDateModel: null,
            perPage: uiSettings.tableRows,
            loading: true,
            sports: [],
            sportsModel: {},
            categories: [],
            categoriesModel: {},
            tournaments: [],
            tournamentsModel: {},
            showFetchBtn: false,
            showCategoryDdl: false,
            showTournamentsDdl: false,
            filtersInitializationDone: false,
            defaultEntityId: 0,
            showFixturesTable: false,
            activeTab: 0,
            showOutrightsTable: false,
            showNoEvents: false,
            errorKeyCount: 0,
            selectedIds: [],
            showModal: false,
            importInProgress: false,
            showImportLoading: false,
        };
    },
    computed: {
        startDateApi() {
            return this.formatApiDate(this.startDateModel);
        },
        endDateApi() {
            return this.formatApiDate(this.endDateModel);
        },
        fixturesActive() {
            return this.activeTab == 0;
        },
    },
    watch: {
        activeTab() {
            this.getSports();
        },
        startDateModel() {
            this.showFixturesTable = false;
            this.sportsModel = {};
            if (this.filtersInitializationDone) {
                this.getSports();
            }
        },
        endDateModel() {
            this.showFixturesTable = false;
            this.sportsModel = {};
            if (this.filtersInitializationDone) {
                this.getSports();
            }
        },
        sportsModel() {
            this.clearFixturesTable();
            this.clearCategoriesDdl();
            this.clearTournamentsDdl();
            this.showTournamentsDdl = false;
            this.showCategoryDdl = false;
            this.showFetchBtn = false;
            this.showFixturesTable = false;

            if (this.notNullOrEmptyObject(this.sportsModel)) {
                if (this.fixturesActive) {
                    this.getCategories();
                } else {
                    this.showFetchBtn = true;
                    this.showCategoryDdl = false;
                    this.showTournamentsDdl = false;
                }
            }
        },
        categoriesModel() {
            this.clearFixturesTable();
            this.clearTournamentsDdl();
            this.showFixturesTable = false;
            this.showTournamentsDdl = false;
            if (this.notNullOrEmptyObject(this.categoriesModel)) {
                this.showFetchBtn = true;
                this.getTournaments();
            }
        },
        tournamentsModel() {
            if (this.notNullOrEmptyObject(this.tournamentsModel)) {
                this.selectedIds = [];
                this.readItems();
            }
        },
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00';

        const tomorrow = new Date(crtDate);
        tomorrow.setDate(tomorrow.getDate() + 1);
        let yearT = tomorrow.getFullYear();
        let monthT = tomorrow.getMonth() + 1;
        if (monthT <= 9) {
            monthT = '0' + monthT;
        }
        let dayT = tomorrow.getDate();
        if (dayT <= 9) {
            dayT = '0' + dayT;
        }
        var endDateString = yearT + '.' + monthT + '.' + dayT + ' 10:00';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);

        this.getSports();
    },
    methods: {
        readItems() {
            this.loading = true;
            this.showNoEvents = false;
            if (this.fixturesActive) {
                var tournamentId = this.notNullOrEmptyObject(this.tournamentsModel) ? this.tournamentsModel.Id : this.defaultEntityId;
                this.BetradarApi.getFixtures(this.sportsModel.Id, this.categoriesModel.Id, tournamentId, this.startDateApi, this.endDateApi)
                    .then((response) => {
                        this.items = response.data;
                        this.loading = false;
                        this.showFixturesTable = true;
                    })
                    .catch((error) => {
                        if (error.response.status == 404) {
                            this.showNoEvents = true;
                            this.showFixturesTable = false;
                            this.errorKeyCount++;
                        } else {
                            this.showToast('error', this.$t('alerts.generalError'));
                        }
                    });
            } else {
                this.BetradarApi.getOutrights(this.sportsModel.Id)
                    .then((response) => {
                        this.itemsOutrights = response.data;
                        this.showOutrightsTable = true;
                        this.loading = false;
                    })
                    .catch((error) => {
                        if (error.response.status == 404) {
                            this.showNoEvents = true;
                            this.showFixturesTable = false;
                            this.errorKeyCount++;
                        } else {
                            this.showToast('error', this.$t('alerts.generalError'));
                        }
                    });
            }
        },
        getSports() {
            this.sportsModel = {};
            this.showFetchBtn = false;
            this.showNoEvents = false;
            if (this.fixturesActive) {
                this.BetradarApi.getSports(this.startDateApi, this.endDateApi)
                    .then((response) => {
                        this.sports = response.data;
                        this.filtersInitializationDone = true;
                    })
                    .catch((error) => {
                        if (error.response.status == 404) {
                            this.showNoEvents = true;
                            this.showFixturesTable = false;
                            this.errorKeyCount++;
                        } else {
                            this.showToast('error', this.$t('alerts.generalError'));
                        }
                    });
            } else {
                this.BetradarApi.getOutrightsSports(this.startDateApi, this.endDateApi)

                    .then((response) => {
                        this.sports = response.data;
                        this.filtersInitializationDone = true;
                    })
                    .catch((error) => {
                        if (error.response.status == 404) {
                            this.showNoEvents = true;
                            this.showOutrightsTable = false;
                            this.errorKeyCount++;
                        } else {
                            this.showToast('error', this.$t('alerts.generalError'));
                        }
                    });
            }
        },
        showToast(toastSeverity, message) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: message, life: this.getToastLifeSeconds() });
        },
        getCategories() {
            this.showFetchBtn = false;
            this.showCategoryDdl = false;
            this.showTournamentsDdl = false;
            this.showNoEvents = false;
            this.BetradarApi.getCategories(this.sportsModel.Id, this.startDateApi, this.endDateApi)
                .then((response) => {
                    this.categories = response.data;
                    this.showCategoryDdl = true;
                    this.showTournamentsDdl = false;
                })
                .catch((error) => {
                    if (error.response.status == 404) {
                        this.showNoEvents = true;
                        this.showOutrightsTable = false;
                        this.errorKeyCount++;
                    } else {
                        this.showToast('error', this.$t('alerts.generalError'));
                    }
                });
        },
        getTournaments() {
            this.showTournamentsDdl = false;
            this.showNoEvents = false;
            this.BetradarApi.getTournaments(this.sportsModel.Id, this.categoriesModel.Id, this.startDateApi, this.endDateApi)
                .then((response) => {
                    this.tournaments = response.data;
                    this.showTournamentsDdl = true;
                })
                .catch((error) => {
                    if (error.response.status == 404) {
                        this.showNoEvents = true;
                        this.showOutrightsTable = false;
                        this.errorKeyCount++;
                    } else {
                        this.showToast('error', this.$t('alerts.generalError'));
                    }
                });
        },
        clearFixturesTable() {
            this.items = [];
            this.showFixturesTable = false;
        },
        clearCategoriesDdl() {
            this.categories = [];
            this.categoriesModel = {};
        },
        clearTournamentsDdl() {
            this.tournaments = [];
            this.tournamentsModel = {};
        },
        confirmImport() {
            if (this.selectedIds.length > 0) {
                this.showModal = true;
            } else {
                this.showToast('info', this.$t('sportsbets.alerts.importNoneSelected'));
            }
        },
        importFixtures() {
            this.showModal = false;
            if (!this.importInProgress) {
                this.importInProgress = true;
                this.showImportLoading = true;
                let fixturesObject = {
                    FixturesIds: this.selectedIds.join(),
                    FixturesActive: this.fixturesActive,
                };
                this.fixtures = [];
                this.BetradarApi.importFixtues(fixturesObject)
                    .then(() => {
                        let importedCount = this.selectedIds.length;
                        this.showImportLoading = false;
                        this.showFixturesTable = false;
                        this.showOutrightsTable = false;
                        this.selectedIds = [];
                        this.importInProgress = false;
                        if (this.fixturesActive) {
                            this.showToast('success', this.$tc('sportsbets.alerts.eventImported', { count: importedCount }));
                        } else {
                            this.showToast('success', this.$tc('sportsbets.alerts.outrightImported', { count: importedCount }));
                        }
                    })
                    .catch(() => {
                        this.showImportLoading = false;
                        this.showErrorImportAlert = true;
                        this.importInProgress = false;
                        this.showToast('error', this.$t('alerts.generalError'));
                    });
            }
        },
        selectAll() {
            let itemsToSelect = this.items;
            if (!this.fixturesActive) {
                itemsToSelect = this.itemsOutrights;
            }
            if (this.selectedIds.length === itemsToSelect.length) {
                this.selectedIds = [];
            } else {
                this.selectedIds = [];
                this.selectedIds = itemsToSelect.map((item) => {
                    return item.id;
                });
            }
        },
        closeModal() {
            this.showModal = false;
        },
    },
};
</script>
