import axios from 'axios'
import settings from '../settings/apis.js'

class BetradarApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].betradarApiUrl,
            withCredentials: false,

            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    getSports(startDate, endDate) {
        return this.Api.get('/offer/sports/' + startDate + '/' + endDate)
    }
    getOutrightsSports() {
        return this.Api.get('/offer/outrightsSports')
    }
    getCategories(sportId, startDate, endDate) {
        return this.Api.get('/offer/sports/' + sportId + '/categories/' + startDate + '/' + endDate)
    }
    getTournaments(sportId, categoryId, startDate, endDate) {
        return this.Api.get('/offer/sports/' + sportId + '/categories/' + categoryId + '/tournaments/' +
            startDate + '/' + endDate)
    }
    getFixtures(sportId, categoryId, tournamentId, startDate, endDate) {
        return this.Api.get('/offer/sports/' + sportId + '/categories/' + categoryId + '/tournaments/' +
            tournamentId + '/fixtures/' + startDate + '/' + endDate)
    }
    getOutrights(sportId) {
        return this.Api.get('/offer/outrightsSports/' + sportId + '/outrights')
    }
    importFixtues(fixtures) {
        return this.Api.post('/offer/importOffer', fixtures)
    }

}

export {
    BetradarApi
}
